import consumer from "./consumer";

(function () {
  const calculationsStatusApp = function () {
    const mustacheTemplate = "#mustache_calculation_item";
    const reportSpinner = ".calculation-report-notices";
    const itemsList = ".calculation-notices .dropdown-menu ul";
    const message = "Your calculation was successfully finished.";
    const loader = ".generic-loader";

    const showOrHideSpinner = (data, reloadPage) => {
      const target = $(itemsList).parent().parent();
      if (data.length > 0) {
        target.removeClass("d-none");
      } else {
        target.addClass("d-none");
        toastr.success(message);
      }
      console.log(data, reloadPage);
      if (!reloadPage) return;

      window.location.reload();
    };

    const calculationsHtml = (data) => {
      let html = "";
      for (let i = 0; i < data.length; i++) {
        let process = data[i];
        html += Mustache.render($(mustacheTemplate).html(), {
          title: process.round_type,
          processType: process.process_type_humanized,
          date: process.created_at,
        });
      }
      return html;
    };

    const generateNextPositionRound = (round) => {
      var url = `/leagues/${gon.league_id}/matches/round_robins/${round.id}?regenerate_type=position-round`;
      $(loader).removeClass("d-none");

      $.ajax({
        method: "PUT",
        url,
      });
    };

    const confirmNextPositionRound = (round, isScoresPage) => {
      const text = "Your next round is a position round would you like to create the pairings now?";

      window.swalConfirm
        .fire({
          title: "Notice",
          text,
          confirmButtonText: `Yes, Create the pairings for ${round.formatted_date}`,
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isConfirmed) return generateNextPositionRound(round);

          if (!isScoresPage) return;

          window.location.reload();
        });
    };

    const subscribeToChannel = () => {
      consumer.subscriptions.create(
        { channel: "CalculationsChannel", league_id: gon.league_id },
        {
          received(data) {
            console.log(data);
            if (data.pending_reports && data.pending_reports > 0)
              $(reportSpinner).removeClass("d-none");
            else $(reportSpinner).addClass("d-none");

            const isScoresPage = window.location.href.includes(`/leagues/${gon.league_id}/scores`);
            if (!data.pending_rounds) {
              if (isScoresPage) window.location.reload();
              return;
            }

            const hasNextRoundAsPosition =
              data.next_round?.round_type === "position" && !data.next_round?.calculated;
            const reloadPage = isScoresPage && !hasNextRoundAsPosition;

            $(itemsList).html(calculationsHtml(data.pending_rounds));
            showOrHideSpinner(data.pending_rounds, reloadPage);

            if (hasNextRoundAsPosition) confirmNextPositionRound(data.next_round, isScoresPage);
          },
        }
      );
    };

    const init = () => {
      if (!gon.league_id || gon.league_id === "") return;

      // if (location.href.includes('localhost:3000')) return;

      subscribeToChannel();
    };

    if (!window.$) return;

    $(document).on("turbolinks:load", init);
  };

  if (typeof window !== "undefined") {
    window.calculationsStatusApp = calculationsStatusApp;
  }

  return calculationsStatusApp();
})();
